<template>
  <div
    class="infographic-banner"
    :style="{backgroundImage: `url(${decorLines})`}"
  >
   <div class="infographic-banner__container container">
     <div class="infographic-banner__row">
       <h2 class="infographic-banner__title" v-html="title" />
       <div class="infographic-banner__data infographic">
          <div
            v-for="infographic in infographics"
            class="infographic__item"
            :key="infographic.num"
          >
            <div class="infographic__num" v-text="infographic.num" />
            <div class="infographic__caption" v-text="infographic.caption" />
          </div>
       </div>
      </div>
   </div>
  </div>
</template>

<script>
import decorLines from '@/assets/images/decor-lines.png';

export default {
  name: 'InfographicBanner',
  data() {
    return {
      decorLines,
      title: 'The Cover Whale story in numbers',
      infographics: [
        {
          num: '300+',
          caption: 'Data Collection Points',
        },
        {
          num: '100%',
          caption: 'Reduction in dashboards for decision making',
        },
        {
          num: '<2',
          caption: 'Minutes response time to issues',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.infographic-banner {
  padding: 70px 0;
  background-repeat: no-repeat;
  background-position: 0 0;

  @include breakpoint(0, $lg) {
    padding: 35px 0;
    background-image: none !important;
  }

  @include breakpoint(0, $md) {
    padding: 25px 0;
  }

  &__row {
    padding: 60px;
    display: flex;
    align-items: center;
    background-color: $primary;
    border-radius: $border-radius * 6;

    @include breakpoint(0, $xl) {
      padding: 50px;
    }

    @include breakpoint(0, $lg) {
      flex-direction: column;
      padding: 40px;
    }

    @include breakpoint(0, $md) {
      padding: 30px;
    }
  }

  &__title {
    width: 30%;
    margin: 0 200px 0 0;
    color: $light;
    font-size: 40px;
    font-weight: 400;

    @include breakpoint(0, $xl) {
      margin-right: 50px;
    }

    @include breakpoint(0, $lg) {
      width: 100%;
      text-align: center;
      margin: 0 0 30px;
      font-size: 36px;
    }

    @include breakpoint(0, $md) {
      font-size: 28px;
    }
  }

  &__data {
    width: 100%;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows:  1fr 1fr;
    align-items: center;
    justify-items: center;

    @include breakpoint(0, $sm) {
      display: block;
    }

    .infographic__item {

      @include breakpoint(0, $sm) {
        margin-bottom: 40px;
      }

      &:last-child {
        @include breakpoint(0, $sm) {
          margin-bottom: 0;
        }
      }

      &:nth-child(1) {
        grid-area: 1 / 2 / 2 / 4;
      }

      &:nth-child(2) {
        grid-area: 2 / 1 / 3 / 3;
      }

      &:nth-child(3) {
        grid-area: 1 / 4 / 3 / 7;
      }

    }

  }

}

.infographic {

  &__num {
    font-size: 80px;
    color: $light;

    @include breakpoint(0, $lg) {
      text-align: center;
      font-size: 50px;
    }

    @include breakpoint(0, $md) {
      font-size: 40px;
    }

  }

  &__caption {
    color: $light;
    font-size: 20px;
    width: 220px;

    @include breakpoint(0, $lg) {
      text-align: center;
      width: auto;
    }
  }

}
</style>
