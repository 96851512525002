<template>
  <div class="process-card">
    <div class="process-card__image" :style="bgColor">
      <main-icon class="process-card__icon" :name="content.icon"  :width="iconWidth" />
      <h4 class="process-card__title" v-text="content.title" :style="{ color: content.color }" />
    </div>
    <div class="process-card__text" v-text="content.text"/>
  </div>
</template>

<script>

export default {
  name: 'ProcessCard',
  props: {
    content: Object,
  },
  data() {
    return {
    };
  },
  computed: {
    iconWidth() {
      return (this.content.icon === 'collection') ? 135 : '';
    },
    bgColor() {
      return {
        backgroundColor: this.content.gradientFrom,
        background: `radial-gradient(circle, ${this.content.gradientFrom} 0%, ${this.content.gradientTo} 100%)`,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.process-card {
  &__image {
    width: 280px;
    height: 280px;
    margin-bottom: 20px;
    padding: 20px 25px;
    background-color:  #FCE8EB;
    background: radial-gradient(circle, #FCE8EB 0%, #FEDCDC 100%);
    border-radius: $border-radius * 6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include breakpoint(0, $lg) {
      margin-bottom: 15px;
    }
  }

  &__icon {
    margin: auto;
  }

  &__title {
    margin-bottom: 0;
    margin-top: auto;
    font-size: 24px;
    color: $red;
    font-weight: 400;
    align-self: flex-start;
  }

  &__text {
    font-size: 18px;
    color: $text;
    font-weight: 700;

    @include breakpoint(0, $lg) {
      font-weight: 400;
      font-size: 16px;
    }

  }

}
</style>
