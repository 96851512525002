<template>
  <div class="newsletter">
    <form
      @submit.prevent
      class="newsletter__form"
    >
      <input class="newsletter__field"
             id="newsletter-email"
             name="newsletter_email"
             type="email"
             required
             placeholder="Your email"
      >
      <main-button class="newsletter__submit btn btn--link btn--icon">
        <main-icon name="newsletter" />
      </main-button>
    </form>
  </div>
</template>

<script>

export default {
  name: 'NewsletterForm',
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.newsletter {

  &__form {
    position: relative;
  }

  &__field {
    max-width: 100%;
    width: 315px;
    display: inline-block;
    vertical-align: middle;
    background-color: $purple-700;
    border-radius: $border-radius * 8;
    padding: 14px 60px 14px 30px;
    box-shadow: inset -2px 5px 0 rgba(0, 0, 0, 0.06);
    text-shadow: 1px 2px 0 rgba(0, 0, 0, 0.16);
    color: $primary;
    border: 0;
    font-size: 17px;

    @include breakpoint(0, $sm) {
      width: 100%;
    }

    &:focus {
      outline: none;
    }

    &::-webkit-input-placeholder {
      color: $primary;
    }
    &::-moz-placeholder {
      color: $primary;
    }
    &:-ms-input-placeholder {
      color: $primary;
    }
    &:-moz-placeholder {
      color: $primary;
    }
  }

  &__submit {
    position: absolute;
    top: 6px;
    right: 6px;
    z-index: 5;
  }

}
</style>
