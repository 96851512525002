<template>
  <div class="">
    <promo-section
      class="promo--about"
      :subtitle="promo.subtitle"
      :title="promo.title"
      :image="promo.image"
    />
    <banner-section />
    <process-section />
    <infographic-banner />
    <team-section />
    <cta-banner
      :title="banner.title"
      :text="banner.text"
    />
  </div>
</template>

<script>
import PromoSection from '@/components/PromoSection.vue';
import BannerSection from '@/components/BannerSection.vue';
import ProcessSection from '@/components/about-page/ProcessSection.vue';
import InfographicBanner from '@/components/about-page/InfographicBanner.vue';
import TeamSection from '@/components/about-page/TeamSection.vue';
import CtaBanner from '@/components/CtaBanner.vue';
import promoImage from '@/assets/images/about-promo.svg';

export default {
  name: 'About',
  components: {
    InfographicBanner,
    PromoSection,
    BannerSection,
    ProcessSection,
    TeamSection,
    CtaBanner,
  },
  data() {
    return {
      promo: {
        subtitle: 'About Us',
        title: 'Navigating our nations highways is complex. Your <span class="text-accent">insurance doesn\'t have to be.</span>',
        image: promoImage,
      },
      banner: {
        title: 'Still in love with how long it takes to get commercial insurance? Don’t click there ->',
        text: 'Getting coverage through CoverWhale. No biggie. Even our call to actions are witty.',
      },
    };
  },
};
</script>
