<template>
  <div
    class="process"
    id="process"
  >
   <div class="process__container container">
     <h2 class="process__title heading" v-html="title" />
     <div class="process__row">
        <div
          v-for="card in processes"
          :key="card.id"
          class="process__col"
        >
          <process-card class="process__card" :content="card" />
        </div>
      </div>
   </div>
  </div>
</template>

<script>
import ProcessCard from '@/components/about-page/ProcessCard.vue';

export default {
  name: 'ProcessSection',
  components: { ProcessCard },
  data() {
    return {
      title: 'This talks about CoverWhale\'s </br> process and commitment to innovation.',
      processes: [
        {
          id: 1,
          icon: 'collection',
          color: '#FF8586',
          gradientFrom: '#FCE8EB',
          gradientTo: '#FEDCDC',
          title: 'Data Collection',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt.',
        },
        {
          id: 2,
          icon: 'analysis',
          color: '#FFA675',
          gradientFrom: '#FEF8E8',
          gradientTo: '#FFE8DD',
          title: 'Data Analysis',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt.',
        },
        {
          id: 3,
          icon: 'coverage',
          color: '#A783FF',
          gradientFrom: 'rgba(116, 138, 253, 0.4)',
          gradientTo: 'rgba(116, 138, 253, 0.4)',
          title: 'Fast Coverage',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elitas, sed do eiusd tempor incididunt.',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.process {
  padding: 100px 0 70px;

  @include breakpoint(0, $lg) {
    padding: 70px 0 35px;
  }

  @include breakpoint(0, $md) {
    ppadding: 35px 0 20px;
  }

  &__row {
    display: flex;
    justify-content: center;

    @include breakpoint(0, $xl) {
      flex-wrap: wrap;
    }

    @include breakpoint(0, $sm) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__col {
    padding: 0 15px;

    @include breakpoint(0, $xl) {
      margin-bottom: 30px;
    }

    @include breakpoint(0, $sm) {
      padding: 0;
      margin-bottom: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__card {
    width: 280px;
    max-width: 100%;
  }
}
</style>
