<template>
  <div class="team-card">
    <div class="team-card__image">
      <img :src="member.image" :alt="member.name">
    </div>
    <div class="team-card__position" v-text="member.position"/>
    <h5 class="team-card__name" v-text="member.name"/>
  </div>
</template>

<script>

export default {
  name: 'TeamCard',
  props: {
    member: Object,
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.team-card {
  &__image {
    width: 314px;
    height: 314px;
    margin-bottom: 15px;

    border-radius: $border-radius * 6;

    @include image-box;
    min-height: 365px;

    //@include breakpoint(0, $lg) {
    //  min-height: 300px;
    //}

    @include breakpoint(0, $sm) {
      min-height: 210px;
    }
  }
  &__position {
    font-size: 16px;
    line-height: 1.4;
    color: $text;
    font-weight: 700;

  }
  &__name {
    margin-bottom: 0;
    font-size: 20px;
    line-height: 1.33;
    color: $text;
  }

}
</style>
