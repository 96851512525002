<template>
  <div
    class="team"
    id="team"
  >
   <div class="team__container container">
     <h2 class="team__title heading" v-html="title" />
     <div class="team__row">
        <div
          v-for="member in teamMembers"
          :key="member.id"
          class="team__col"
        >
          <team-card class="team__card" :member="member" />
        </div>
      </div>
   </div>
  </div>
</template>

<script>
import TeamCard from '@/components/about-page/TeamCard.vue';
import jasonBonnety from '@/assets/images/jason-bonnety.jpg';
import sanDeGrazia from '@/assets/images/san-de-grazia.jpg';
import kevinSmith from '@/assets/images/kevin-smith.jpg';
import mittRondey from '@/assets/images/mitt-rondey.jpg';
import alexandraSlaterImage from '@/assets/images/alexandra-slater.jpg';
import johnBissell from '@/assets/images/john-bissell.jpg';

export default {
  name: 'TeamSection',
  components: { TeamCard },
  data() {
    return {
      title: 'The team behind CoverWhale',
      teamMembers: [
        {
          id: 1,
          image: jasonBonnety,
          name: 'Jason Bonnety',
          position: 'Founder',
        },
        {
          id: 2,
          image: sanDeGrazia,
          name: 'San De Grazia',
          position: 'CEO',
        },
        {
          id: 3,
          image: kevinSmith,
          name: 'Kevin Smith',
          position: 'COO',
        },
        {
          id: 4,
          image: mittRondey,
          name: 'Mitt Rondey',
          position: 'CTO',
        },
        {
          id: 5,
          image: alexandraSlaterImage,
          name: 'Alexandra Slater',
          position: 'GMO',
        },
        {
          id: 6,
          image: johnBissell,
          name: 'John Bissell',
          position: 'CTF',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.team {
  padding: 70px 0;

  @include breakpoint(0, $lg) {
    padding: 35px 0;
  }

  @include breakpoint(0, $sm) {
    padding: 25px 0;
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 40px;

    @include breakpoint(0, $xlg) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 30px;
    }

    @include breakpoint(0, $md) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }
  }

  &__title {

    @include breakpoint(0, $sm) {
      display: none;
    }

  }
}
</style>
